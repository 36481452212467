import React from 'react';
import { Layout } from "../components/layout";
import { navigate } from "gatsby"

class RootIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      screenWidth: 0
    }
  }

  componentDidMount() {
    const hrefnoslash = window.location.href.charAt(window.location.href.length - 1) === '/' ? window.location.href.slice(0, -1) : window.location.href;
    if (hrefnoslash == window.location.origin) navigate(`/home`);
    else navigate(`/404`);
  }

  render() {
    return (
      <Layout breadcrumbs={["Index"]}>
        <div id="srcindex" className="container">
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </Layout >
    )
  }
}

export default RootIndex